<template>
  <v-layout>
    <h1>Dashboard</h1>
  </v-layout>
</template>

<script>
export default {}
</script>

<style></style>
